/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';


@import '@angular/cdk/overlay-prebuilt.css';

/* See why here https://github.com/fullcalendar/fullcalendar-angular/blob/master/projects/fullcalendar/src/lib/fullcalendar.component.scss */
// @import 'fullcalendar-scheduler';

[val-message] {
  color: var(--ion-color-danger, red);
}


// Autcomplete styles
// @see https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--ion-color-primary, white);
  box-shadow: 0 0 0px 100px var(--ion-item-background, var(--ion-background-color, #fff)) inset;
}

[hidden] {
  display: none !important;
}

.selectable {
  --selectable-outline-size: 2px;

  outline: var(--selectable-outline-size) solid rgba(0, 0, 0, 0);
  transition:
    outline 300ms
  ;

  &:focus {
    outline:1px solid var(--ion-color-primary, #3880ff);
  }

  box-shadow: 0 0 var(--selectable-outline-size) 0 rgba(0,0,0,0.1), 0 var(--selectable-outline-size) var(--selectable-outline-size) 0 rgba(0,0,0,0.2);


  &.warn {
    outline: calc(var(--selectable-outline-size) / 2) solid var(--ion-color-secondary, yellow);
  }

  &.selected {
    outline: var(--selectable-outline-size) solid var(--ion-color-secondary, yellow);
    box-shadow: none;
  }

  &.invalid {
    outline: calc(var(--selectable-outline-size) / 2) solid var(--ion-color-danger, red);
  }

}


button.alert-button.danger {
  color: var(--ion-color-danger, red);
}

button.alert-button.secondary {
  color: var(--ion-color-secondary, yellow);
}

button.alert-button.alert-button-role-submit {
  color: var(--ion-color-secondary, yellow);;
}

hr {
  background-color: currentColor;
  opacity: 0.12;
}


ion-alert button.alert-danger {
  color: var(--ion-color-danger, red) !important;
}

.exercise-container-modal::part(content) {
  width: 400px;
}

ion-footer ion-toolbar {
  --background: var(--ion-background-color);
}

@media (max-width: 400px) {
  ion-footer.buttons-container ion-button {
    width: 100%;
    &.ion-margin-end {
      margin-inline-end: 0;
    }

    // Any ion-button with one under it, should have some spacing on bottom
    & + ion-button {
      margin-bottom: calc(var(--ion-padding, 16px) / 2);
    }
  }

  // The new way of using buttons-container. .buttons-container on ion-footer needs to go in favour of
  // ion-footer ion-row.buttons-container
  ion-footer ion-row.buttons-container {
    flex-direction: column;
    ion-button {
      width: 100%;
    }
  }
}

.toast-danger {
  --color: var(--ion-color-danger, red);
}


.ag-theme-alpine-dark {
  font-family: var(--ion-font-family);
}

.fusejs-highlight em {
  color: var(--ion-color-secondary);
  font-style: normal;
}

.fullscreen-modal {
  --width: 100dvw;
  --height: 100dvh;
}

.notification-popover {
  --width: 400px;
}

@media screen and (max-width: 400px) {
  .notification-popover {
    // Why 12px? Because popover-content has a small left padding of that amount
    --width: calc(100% - (12px * 2));
  }
}

.margin-start-auto {
  margin-inline-start: auto;
}

.margin-end-auto {
  margin-inline-end: auto;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.white-space-normal {
  white-space: normal;
}

.update-group-workout-modal {
  --width: 80vw;
  --height: 80vh;
  --max-width: 800px;
  --max-height: 700px;
}

.ag-grid-multi-select-popover {
  --max-height:	400px;
}

// ion-toast global override, the default text color for ion-toast is white, which is not good for dark mode
ion-toast::part(button) {
  --button-color: currentColor;
  border-radius: 4px;
  border: 1px solid var(--ion-border-color);
}

// Temp solution until we buy ag-grid enterprise
.ag-watermark {
  display: none;
}

.simple-table-container {
  overflow-x: auto;
  width: 100%;
  th, td {
    padding: calc(var(--ion-padding) / 2);
    text-align: start;
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th {
    font-weight: normal;
  }

  table, th, td {
    border: 1px solid var(--ion-color-step-300);
  }
}


ion-row.buttons-container {
  column-gap: var(--ion-padding);
  row-gap: calc(var(--ion-padding) / 2);
  ion-button {
    margin: 0;
  }
}

ion-button.round-edges {
  --border-radius: 20px;
}

// This will allow us to have a clickable ion-item + checkbox next to each other
.item-checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  ion-item:not(.checkbox-item) {
    flex-grow: 1;
  }

  ion-item.checkbox-item {
    width: 50px;
    flex-shrink: 0;
  }

  ion-item.checkbox-item::part(native) {
    height: 100%;
  }
}
